import { useTranslation } from 'react-i18next';

import { LogoContainer } from './Logo.styled';

import { Logo as LogoIcon } from 'assets/icons';

const Logo = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();

  return (
    <LogoContainer {...props}>
      <LogoIcon title={t('general.logo')} />
    </LogoContainer>
  );
};

export default Logo;
