export const BASE_AUTH_URL = `${process.env.REACT_APP_BASE_URL}/auth`;
export const BASE_USERS_URL = `${process.env.REACT_APP_BASE_URL}/users`;
export const BASE_CONTENT_URL = `${process.env.REACT_APP_BASE_URL}/content`;

export const QUERY_KEYS = {
  PROJECT_HIERARCHY: 'projects-hierarchy',
  SITES: 'sites',
  USER: 'user',
  VIEWS: 'views',
  WORKBOOK: 'workbook',
  WORKBOOKS: 'workbooks',
};
