import {
  Teewinot,
  CookBynum,
  Mbb,
  Durable,
  SolsteinCapital,
  Praxis,
  SnowLake,
  JerichoCapital,
  Scopus,
  Whittier,
  Ravenswood,
  Fusion,
  CityDiffrentInvestments,
  LongPondCapital,
  HeardCapital,
  ThornTree,
} from 'assets/icons';

export const clientIdToLogoMapper: Record<string, React.FunctionComponent> = {
  'EB4E1D43-D597-42CC-B4AA-00BD97F35CAD': Teewinot,
  'F270CABC-74F1-48EA-9439-09825B960E14': CookBynum,
  '5B65B4B0-2F5F-4828-853D-34FF77EADD15': Mbb,
  '527A0627-AAB7-463D-810A-3BBD4CA179CB': Durable,
  'BFA11775-56A2-41A0-9F9F-4FF67D2356EC': SolsteinCapital,
  '854044C8-4C9F-4E00-B744-5090C82D7C94': Praxis,
  '3F5CA3BF-7D6A-4591-8F8C-69E710BA6ED8': SnowLake,
  'A35714D5-19EC-4DFE-A3AA-7F1CD8D843CB': JerichoCapital,
  '37597A9A-E828-43B6-806C-91069287832E': Scopus,
  'AE145899-CA31-4E42-B115-96E9D7F897E7': Whittier,
  'D32EA4CD-7B45-413B-8A25-A8F44D86BBB2': Ravenswood,
  'F68AFE99-CC5F-446E-B0C7-BD0A7AA2F8E6': Fusion,
  'B4394509-EF0A-4A55-B251-BDCA4700B137': CityDiffrentInvestments,
  'C2D5A22A-95D8-4E5A-8C00-C7C115EC900B': LongPondCapital,
  'DE292EE8-37E3-49DF-AFFC-EED97289F5FB': HeardCapital,
  '6DD9FC42-6DE6-429C-A791-F83973043B52': ThornTree,
};
