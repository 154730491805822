import { objectKeys } from '@hummingbird/shared';
import { useMemo } from 'react';

import useQueryParams from '../useQueryParams';

import { CLIENT_ID_TO_BRANDING_MAPPER } from './constants';

const useClientBranding = () => {
  const [{ clientId }] = useQueryParams();

  const isLoading = !clientId;

  const brandingConfig = useMemo(() => {
    const isConfigDefinedForClient = objectKeys(
      CLIENT_ID_TO_BRANDING_MAPPER,
    ).includes(clientId);

    const key = isConfigDefinedForClient ? clientId : 'default';

    return CLIENT_ID_TO_BRANDING_MAPPER[key];
  }, [clientId]);

  const { intercomColor, primaryColor, logo } = brandingConfig;

  return {
    intercomColor,
    primaryColor,
    logo: isLoading ? null : logo,
  };
};

export default useClientBranding;
