/* eslint-disable no-console */

function get(key: string): unknown {
  try {
    return JSON.parse(localStorage.getItem(key) || 'null');
  } catch (error) {
    console.log(error);

    return null;
  }
}

function set(key: string, value: unknown): void {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.log(error);
  }
}

function remove(key: string): void {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.log(error);
  }
}

/* eslint-enable no-console */

export default { get, set, remove };
