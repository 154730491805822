import { FC, ReactNode } from 'react';
import { Store } from 'react-notifications-component';

interface NotificationProps {
  title: string | FC | ReactNode;
  message: string | FC | ReactNode;
  type: 'success' | 'danger' | 'info' | 'default' | 'warning';
  duration?: number;
  onClick?: () => void;
}

export const addNotification = ({
  duration,
  onClick,
  ...args
}: NotificationProps) =>
  Store.addNotification({
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: duration || 5000,
      pauseOnHover: true,
    },
    onRemoval: (_, eventType) => {
      if (eventType === 'click' && onClick) onClick();
    },
    ...args,
  });
