import { BASE_AUTH_URL, BASE_CONTENT_URL } from './constants';

import axios from 'config/axios';

export interface EmbedTokenPayload {
  idToken: string;
  siteId: string;
}

export const embedToken = async (
  payload: EmbedTokenPayload,
): Promise<EmbedTokenResponse> => {
  const { idToken, siteId } = payload;

  const { data } = await axios.post(
    `${BASE_AUTH_URL}/embed_token`,
    {},
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
        'x-site-id': siteId,
      },
    },
  );

  return data;
};

export const getUser = async (): Promise<UserData> => {
  const { data } = await axios.post(`${BASE_AUTH_URL}/whoami`);

  return data;
};

export const getProjectsHierarchy = async (
  siteId: string,
): Promise<Record<number, ProjectHierarchyObject[]>> => {
  const { data } = await axios.get(`${BASE_CONTENT_URL}/hierarchy`, {
    headers: { 'x-site-id': siteId },
  });

  return data;
};

export const getSites = async (): Promise<Site[]> => {
  const { data } = await axios.get(`${BASE_CONTENT_URL}/sites`);

  return data;
};

export const getSite = async (id: string): Promise<Site> => {
  const { data } = await axios.get(`${BASE_CONTENT_URL}/sites/${id}`, {
    headers: { 'x-site-id': id },
  });

  return data;
};

export const getWorkbook = async (
  workbookId: string,
  siteId: string,
): Promise<Workbook> => {
  const { data } = await axios.get(
    `${BASE_CONTENT_URL}/workbooks/${workbookId}`,
    {
      headers: {
        'x-site-id': siteId,
      },
    },
  );

  return data;
};

// TODO Update types
export const getViews = async (
  workbookId: string,
  siteId: string,
): Promise<View[]> => {
  const { data } = await axios.get(
    `${BASE_CONTENT_URL}/workbooks/${workbookId}/views`,
    {
      headers: {
        'x-site-id': siteId,
      },
    },
  );

  return data;
};
