import ReactGA from 'react-ga4';

import { GOOGLE_ANALYTICS_ID } from './secrets';

declare global {
  interface Window {
    gtag: (event: string, action: string, params: GTagParams) => void;
    dataLayer: any[];
  }
}

declare global {
  interface Window {}
}

const initializeGoogleAnalytics = () => {
  // Initialize the Google Analytics
  ReactGA.initialize(GOOGLE_ANALYTICS_ID as string);

  window.dataLayer = window.dataLayer || [];

  const gtagScript = document.createElement('script');

  gtagScript.async = true;
  gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`;

  gtagScript.onload = () => {
    // Once the gtag script is loaded, configure it
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    // @ts-ignore
    gtag('js', new Date());
    // @ts-ignore
    gtag('config', GOOGLE_ANALYTICS_ID);
  };

  document.head.appendChild(gtagScript);
};

export default initializeGoogleAnalytics;
