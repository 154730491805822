import { Spinner } from '@hummingbird/shared';

import { Container } from './CenteredSpinner.styled';

const CenteredSpinner = () => (
  <Container>
    <Spinner aria-label="Loading Spinner" data-testid="spinner" size={32} />
  </Container>
);

export default CenteredSpinner;
