import { useEffect, useState } from 'react';

import Header from './Header/Header';
import { Content, ContentWrapper } from './ProtectedLayout.styled';

import Sidebar from 'components/Sidebar/Sidebar';

const MEDIUM_TO_LARGE_SCREEN_WIDTH_BREAKPOINT = 1199;

interface Props {
  children: JSX.Element;
}

const ProtectedLayout = ({ children }: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isLargeScreen = windowWidth > MEDIUM_TO_LARGE_SCREEN_WIDTH_BREAKPOINT;

  return (
    <ContentWrapper>
      {isLargeScreen && <Sidebar />}
      <Content>
        <Header isLargeScreen={isLargeScreen} />
        {children}
      </Content>
    </ContentWrapper>
  );
};

export default ProtectedLayout;
