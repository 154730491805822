import { Arrow as DownArrow, Flex } from '@hummingbird/shared';
import { useState } from 'react';

import { NameContainer, UpArrow } from './MenuItem.styled';

interface Props {
  children: React.ReactNode;
  name: string;
  levelOfNest: number;
}

const MenuItem = ({ name, children, levelOfNest }: Props) => {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <>
      <NameContainer
        $isExpanded={isExpanded}
        $levelOfNest={levelOfNest}
        aria-expanded={isExpanded}
        role="menuitem"
        onClick={() => setExpanded(prev => !prev)}>
        <span>{name}</span>
        {isExpanded ? <UpArrow /> : <DownArrow />}
      </NameContainer>

      {isExpanded && <Flex direction="column">{children}</Flex>}
    </>
  );
};

export default MenuItem;
