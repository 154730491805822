import {
  isNullish,
  objectEntries,
  objectFromEntries,
} from '@hummingbird/shared';
import { useCallback, useMemo } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  NavigateOptions,
} from 'react-router-dom';

const useQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = useMemo(
    () => objectFromEntries(createSearchParams(location.search).entries()),
    [location.search],
  );

  const setQueryParams = useCallback(
    (
      params: Record<string, string | null | undefined>,
      navigateOptions?: NavigateOptions,
    ) => {
      let searchParams = createSearchParams(location.search);

      objectEntries(params).forEach(([param, value]) => {
        if (!isNullish(value)) {
          searchParams.set(param, value);
        } else {
          searchParams.delete(param);
        }
      });

      navigate(`?${searchParams}`, navigateOptions);
    },
    [location.search, navigate],
  );

  return [queryParams, setQueryParams] as [
    Record<string, string>,
    typeof setQueryParams,
  ];
};

export default useQueryParams;
