import { useQuery } from '@tanstack/react-query';

import { getViews } from './requests';

import { UseQueryLocalOptions } from 'config/queryClient';
import { useAuth } from 'context/authContext';
import { QUERY_KEYS } from 'services/api/constants';

const useViews = (
  workbookId: string,
  options?: UseQueryLocalOptions<View[]>,
) => {
  const { siteId } = useAuth();

  return useQuery(
    [QUERY_KEYS.VIEWS, workbookId],
    () => getViews(workbookId, siteId),
    {
      ...options,
      enabled: !!workbookId,
    },
  );
};

export default useViews;
