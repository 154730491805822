import { useCallback } from 'react';

import { category } from './constants';

import { useAuth } from 'context/authContext';

interface CustomEventPayload {
  action: string;
  params?: GTagParams;
}

const useGoogleAnalytics = () => {
  const { accountInfo } = useAuth();

  const sendCustomEvent = useCallback(
    ({ action, params = {} }: CustomEventPayload) => {
      window.gtag('event', action, {
        label: accountInfo?.username || '',
        ...params,
      });
    },
    [accountInfo?.username],
  );

  const sendAccountEvent = useCallback(
    ({ action, params }: CustomEventPayload) => {
      sendCustomEvent({
        action,
        params: { category: category.account, ...params },
      });
    },
    [sendCustomEvent],
  );

  const sendInteractionEvent = useCallback(
    ({ action, params }: CustomEventPayload) => {
      sendCustomEvent({
        action,
        params: { category: category.interaction, ...params },
      });
    },
    [sendCustomEvent],
  );

  const sendReportEvent = useCallback(
    ({ action, params }: CustomEventPayload) => {
      sendCustomEvent({
        action,
        params: { category: category.report, ...params },
      });
    },
    [sendCustomEvent],
  );

  return {
    sendAccountEvent,
    sendInteractionEvent,
    sendReportEvent,
  };
};

export default useGoogleAnalytics;
