import { fontSizes, fontWeights } from '@hummingbird/shared';
import styled from 'styled-components';

import { colors } from 'theme';

export const Container = styled.div`
  position: relative;
  display: flex;
  height: 100vh;

  img {
    position: absolute;
  }
`;

export const Card = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  margin: auto;
  padding: 100px 100px 64px;
  background-color: ${colors.gray4};
  border-radius: 30px;
`;

export const Title = styled.h1`
  max-width: 213px;
  color: ${colors.navy};
  font-weight: ${fontWeights.large};
  font-size: ${fontSizes.xxxl};
  font-family: WhyteInktrap;
  line-height: 40px;
  text-align: center;
`;
