import { useIsAuthenticated } from '@azure/msal-react';
import { useEffect } from 'react';

import { ProtectedLayout } from 'components';
import { useAuth } from 'context/authContext';
import Sites from 'pages/Sites/Sites';
import { getUserSession } from 'services/auth';

interface Props {
  children: JSX.Element;
}

const ProtectedRoute = ({ children }: Props) => {
  const { siteId, setSiteId } = useAuth();
  const isAuthenticated = useIsAuthenticated();

  const session = getUserSession();

  useEffect(() => {
    if (!siteId && !!session.siteId) {
      setSiteId(session.siteId);
    }
  }, [session.siteId, setSiteId, siteId]);

  if (!siteId && !session.siteId) {
    return <Sites />;
  }

  return isAuthenticated ? <ProtectedLayout>{children}</ProtectedLayout> : null;
};

export default ProtectedRoute;
