import { MOUSEFLOW_ANALYTICS_ID } from './secrets';

declare global {
  interface Window {
    _mfq: unknown[];
  }
}

const initMouseflow = () => {
  window._mfq = window._mfq || [];

  const mouseflowScript = document.createElement('script');

  mouseflowScript.type = 'text/javascript';
  mouseflowScript.defer = true;
  mouseflowScript.src = `//cdn.mouseflow.com/projects/${MOUSEFLOW_ANALYTICS_ID}.js`;
  document.getElementsByTagName('head')[0].appendChild(mouseflowScript);
};

export default initMouseflow;
