import ContentLoader from 'react-content-loader';

import contentLoaderConfig from 'config/contentLoader';

const rowHeight = 40;
const borderSpacing = 4;

const getY = (index: number) =>
  borderSpacing + index * (rowHeight + borderSpacing);

const SidebarMenuPlaceholder = () => (
  <ContentLoader {...contentLoaderConfig} height={220}>
    {[...Array(5).keys()].map(index => (
      <rect
        height={rowHeight}
        key={index}
        rx={12}
        ry={12}
        width="100%"
        y={index === 0 ? borderSpacing : getY(index)}
      />
    ))}
  </ContentLoader>
);

export default SidebarMenuPlaceholder;
