const eventTypes = {
  CustomViewLoaded: 'customviewloaded',
  CustomViewRemoved: 'customviewremoved',
  CustomViewSaved: 'customviewsaved',
  CustomViewSetDefault: 'customviewsetdefault',
  EditButtonClicked: 'editbuttonclicked',
  EditInDesktopButtonClicked: 'editindesktopbuttonclicked',
  FilterChanged: 'filterchanged',
  FirstVizSizeKnown: 'firstvizsizeknown',
  FirstInteractive: 'firstinteractive',
  CustomMarkContextMenuEvent: 'custommarkcontextmenu',
  MarkSelectionChanged: 'markselectionchanged',
  ParameterChanged: 'parameterchanged',
  ToolbarStateChanged: 'toolbarstatechanged',
  WorkbookReadyToClose: 'workbookreadytoclose',
  WorkbookPublished: 'workbookpublished',
  WorkbookPublishedAs: 'workbookpublishedas',
  UrlAction: 'urlaction',
  TabSwitched: 'tabswitched',
  StoryPointSwitched: 'storypointswitched',
  VizLoadError: 'vizloaderror',
  IframeSrcUpdated: 'iframesrcupdated',
};

export default eventTypes;
