import {
  Dropdown as BaseDropdown,
  Spinner,
  fontSizes,
  fontWeights,
  colors,
} from '@hummingbird/shared';
import styled from 'styled-components';

import { boxShadows } from 'theme';

export const SidebarContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  width: 288px;

  @media (max-width: 480px) {
    width: 226px;
  }
`;

export const UserData = styled.div.attrs({ id: 'user-menu' })`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  background-color: ${colors.white};
  border-radius: 12px;
  box-shadow: ${boxShadows.elevation2};
`;

export const WelcomeNote = styled.span`
  margin: 12px 0 20px;
  color: ${colors.night};
  font-weight: ${fontWeights.regular};
  font-size: ${fontSizes.m};
`;

const Dropdown = styled(BaseDropdown)`
  input {
    height: 40px;
  }

  svg {
    top: 10px;
    width: 24px;
    height: 24px;
  }
`;

export const FundDropdown = styled(Dropdown)`
  margin-top: 16px;

  label span {
    margin: 0 0 2px 4px;
    color: ${colors.mouse};
    font-weight: ${fontWeights.regular};
    font-size: ${fontSizes.xs};
  }

  svg {
    top: 30px;
  }
`;

export const LoaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const SpinnerIcon = styled(Spinner)<{ $hasLabel?: boolean }>`
  position: absolute;
  top: ${({ $hasLabel }) => $hasLabel && '48px'};
  left: ${({ $hasLabel }) => ($hasLabel ? 14 : 24)}px;
  z-index: 1;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
`;

export const SettingsMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: auto;
`;
