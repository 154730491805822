import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  z-index: 1000;
  padding: 20px;
  text-align: center;
  background: white;
  border-radius: 5px;
`;
