import { useQuery } from '@tanstack/react-query';

import { getWorkbook } from './requests';

import { UseQueryLocalOptions } from 'config/queryClient';
import { useAuth } from 'context/authContext';
import { QUERY_KEYS } from 'services/api/constants';

const useWorkbook = (
  workbookId: string,
  options?: UseQueryLocalOptions<Workbook>,
) => {
  const { siteId } = useAuth();

  return useQuery(
    [QUERY_KEYS.WORKBOOK, workbookId],
    () => getWorkbook(workbookId, siteId),
    options,
  );
};

export default useWorkbook;
