import defaultAxiosInstance from 'axios';

import msalInstance, { scopes } from './msal';

import { clearUserSession, saveTokenToUserSession } from 'services/auth';
import { addNotification } from 'services/notifications';

const axios = defaultAxiosInstance.create();

// Set a domain prefix in env.local to use for testing locally.
const OPTIONAL_DOMAIN_PREFIX = `${
  process.env.REACT_APP_OPTIONAL_DOMAIN_PREFIX || ''
}`;

axios.interceptors.request.use(async instance => {
  instance.headers[
    'x-portal-domain'
  ] = `${OPTIONAL_DOMAIN_PREFIX}${window.location.host}`;

  const { account, idToken } = await msalInstance.acquireTokenSilent({
    scopes,
  });

  msalInstance.setActiveAccount(account);
  saveTokenToUserSession(idToken);
  instance.headers.Authorization = `Bearer ${idToken}`;

  return instance;
});

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      clearUserSession();
      msalInstance.logoutRedirect();
    } else if (error.response?.status === 403) {
      addNotification({
        type: 'danger',
        title: 'Error',
        message: error.response.data.message,
      });
    }
    // return Promise.reject(error);
  },
);

export { defaultAxiosInstance };
export default axios;
