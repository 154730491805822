import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { Suspense, useEffect } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import { RouterProvider } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import { INTERCOM_APP_ID } from './constants';

import { scopes } from 'config/msal';
import queryClient from 'config/queryClient';
import { AuthProvider } from 'context/authContext';
import Login from 'pages/Login/Login';
import router from 'routes/router';
import { action } from 'services/analytics/constants';
import initializeGoogleAnalytics from 'services/analytics/initializeGoogleAnalytics';
import initMouseflow from 'services/analytics/initMouseflow';
import useGoogleAnalytics from 'services/analytics/useGoogleAnalytics';
import { saveTokenToUserSession } from 'services/auth';
import { GlobalStyle } from 'theme';

import 'config/i18n';
import 'react-notifications-component/dist/theme.css';

function App() {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  initializeGoogleAnalytics();
  initMouseflow();

  const { sendAccountEvent } = useGoogleAnalytics();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    sendAccountEvent({
      action: action.account.loggedIn,
    });

    const activeAccount = instance.getAllAccounts()?.[0];

    instance
      .acquireTokenSilent({
        scopes,
        account: activeAccount,
      })
      .then(({ idToken, account }) => {
        instance.setActiveAccount(account);
        saveTokenToUserSession(idToken);
      })
      .catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.loginRedirect();
        }
      });
  }, [instance, isAuthenticated, sendAccountEvent]);

  return (
    <Suspense fallback={null}>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            {isAuthenticated ? <RouterProvider router={router} /> : <Login />}
          </AuthProvider>
        </QueryClientProvider>
      </IntercomProvider>

      <ReactNotifications />
      <GlobalStyle />
    </Suspense>
  );
}

export default App;
