import styled from 'styled-components';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  width: calc(100vw - 40px);
  height: calc(100vh - 40px);
  margin: 0 auto;
  padding: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
  border-radius: 12px;

  @media (max-width: 1199px) {
    gap: 12px;
  }
`;
