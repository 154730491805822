import ContentLoader from 'react-content-loader';

import Overlay from '../Overlay/Overlay';

import contentLoaderConfig from 'config/contentLoader';

const maxHeight = 500;
const rowWidth = 60;
const borderSpacing = 16;
const chartLineSpacing = 54;

const getHeight = (index: number) => maxHeight - index * chartLineSpacing;
const getY = (index: number) => index * chartLineSpacing;

const getX = (index: number) =>
  borderSpacing + index * (rowWidth + borderSpacing);

const TableauVizPlaceholder = () => (
  <>
    <Overlay text="Content is loading..." />
    <ContentLoader
      {...contentLoaderConfig}
      height={maxHeight}
      style={{ position: 'absolute' }}
      width={480}>
      [
      {[...Array(6).keys()].map(index => (
        <rect
          height={index === 0 ? maxHeight : getHeight(index)}
          key={index}
          rx={12}
          ry={12}
          width={rowWidth}
          x={index === 0 ? borderSpacing : getX(index)}
          y={getY(index)}
        />
      ))}
      ]
    </ContentLoader>
  </>
);

export default TableauVizPlaceholder;
