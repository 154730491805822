import storage from './storage';

export const saveTokenToUserSession = (idToken: string) => {
  storage.set('idToken', idToken);
};

export const saveSiteIdToUserSession = (siteId: string) => {
  storage.set('siteId', siteId);
};

export const getUserSession = (): { idToken?: string; siteId?: string } => {
  const idToken = storage.get('idToken') as string | undefined;
  const siteId = storage.get('siteId') as string | undefined;

  return { idToken, siteId };
};

export const clearUserSession = () => {
  storage.remove('idToken');
  storage.remove('siteId');
};
