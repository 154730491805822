import { useMsal } from '@azure/msal-react';
import {
  BackArrow,
  Button,
  Dropdown,
  Flex,
  type DropdownOption,
} from '@hummingbird/shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInterval } from 'usehooks-ts';

import { Card, Container, Title } from './Sites.styled';

import authBackground from 'assets/images/authBackground.png';
import { CenteredSpinner, Logo } from 'components';
import { useAuth } from 'context/authContext';
import { action } from 'services/analytics/constants';
import useGoogleAnalytics from 'services/analytics/useGoogleAnalytics';
import useSites from 'services/api/useSites';
import {
  clearUserSession,
  getUserSession,
  saveSiteIdToUserSession,
} from 'services/auth';

const Sites = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const { sendAccountEvent } = useGoogleAnalytics();
  const { setSiteId } = useAuth();
  const { idToken } = getUserSession();

  const [options, setOptions] = useState<DropdownOption[]>();
  const [selectedOption, setSelectedOption] = useState<string>();

  const { isLoading, refetch, isError } = useSites({
    onSuccess: sites => {
      const mappedOptions = sites.map(site => ({
        label: site.portal_name,
        value: site.id,
      }));

      const shouldAutomaticallyChooseSite = sites.length === 1;

      if (shouldAutomaticallyChooseSite) {
        const [singleSite] = sites;

        setSiteId(singleSite.id);

        return;
      }

      setOptions(mappedOptions);

      if (sites.length) {
        const initiallySelectedOption = sites[0].id;

        setSelectedOption(initiallySelectedOption);
      }
    },
    enabled: !!idToken,
  });

  useInterval(refetch, !idToken ? 2000 : null);

  const handleOnConfirm = () => {
    if (!selectedOption) {
      return;
    }

    setSiteId(selectedOption);
    saveSiteIdToUserSession(selectedOption);
  };

  const handleLogout = () => {
    instance.logoutRedirect();
    clearUserSession();

    sendAccountEvent({
      action: action.account.loggedOut,
    });
  };

  if (isError) {
    return (
      <Container>
        <img alt={t('general.backgroundImage')} src={authBackground} />
        <Card>
          <Logo />
          <Button
            icon={BackArrow}
            mode="outlined"
            size="big"
            onClick={handleLogout}>
            {t('general.backToLogin')}
          </Button>
        </Card>
      </Container>
    );
  }

  return (
    <Container>
      <img alt={t('general.backgroundImage')} src={authBackground} />
      {!options ? (
        <CenteredSpinner />
      ) : (
        <Card>
          <Logo />
          <Flex direction="column">
            <Title>{t('general.chooseSite')}</Title>
            <Dropdown
              inputProps={{
                label: t('general.chooseSite'),
                isLabelHidden: true,
                disabled: isLoading,
              }}
              isDisabled={isLoading}
              options={options}
              value={selectedOption || ''}
              onChange={value => setSelectedOption(value as string)}
            />
          </Flex>
          <Button
            disabled={!selectedOption}
            size="big"
            onClick={handleOnConfirm}>
            {t('general.confirm')}
          </Button>
        </Card>
      )}
    </Container>
  );
};

export default Sites;
