import { PublicClientApplication } from '@azure/msal-browser';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '';
const TENANT_ID = process.env.REACT_APP_TENANT_ID || '';

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: CLIENT_ID,
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
});

export const scopes = ['User.Read'];
export default msalInstance;
