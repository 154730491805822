// Docs: https://help.tableau.com/current/api/embedding_api/en-us/index.html

import { useEffect, useRef } from 'react';

import eventTypes from './eventTypes';
import { TableauVizContainer } from './TableauViz.styled';

import { useAuth } from 'context/authContext';
import { action } from 'services/analytics/constants';
import useGoogleAnalytics from 'services/analytics/useGoogleAnalytics';
import { useViews } from 'services/api';
import { useQueryParams } from 'services/hooks';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'viz-parameter': {
        key?: any;
        name: string;
        value: string;
      };
    }
  }
}

interface Props {
  url: string;
  embedParams?: EmbedParams[];
}

const TableauViz = ({ url, embedParams }: Props) => {
  const [{ clientId, fundId, workbookId }, setQueryParams] = useQueryParams();
  const { siteId, embedTableauToken, tableauToken } = useAuth();
  const { sendReportEvent } = useGoogleAnalytics();

  const vizRef = useRef<HTMLElement>(null);

  const { data: views } = useViews(workbookId);

  useEffect(() => {
    const handleFirstInteractive = () => {
      sendReportEvent({
        action: action.report.reportLoadSuccess,
        params: { report_url: url, clientId, site_id: siteId, fund_id: fundId },
      });
    };

    const handleVizLoadError = (event: Event) => {
      const tableauError = event as unknown as { detail: { _message: string } };

      const { statusCode } = JSON.parse(tableauError.detail._message) as {
        statusCode: number;
      };

      sendReportEvent({
        action: action.report.reportLoadError,
        params: { report_url: url, clientId, site_id: siteId, fund_id: fundId },
      });

      if (statusCode === 401) {
        embedTableauToken(siteId);
      }
    };

    const handleTabChange = (event: Event) => {
      const tabEvent = event as unknown as {
        detail: { _newSheetName: string };
      };

      const newSelectedTab = views?.find(
        view => view.name === tabEvent.detail._newSheetName,
      );

      if (newSelectedTab) {
        setQueryParams({
          viewId: newSelectedTab.id,
        });
      }
    };

    const vizElement = vizRef.current;

    if (vizElement) {
      vizElement.addEventListener(
        eventTypes.FirstInteractive,
        handleFirstInteractive,
      );

      vizElement.addEventListener(eventTypes.TabSwitched, handleTabChange);

      vizElement.addEventListener(eventTypes.VizLoadError, handleVizLoadError);
    }

    return () => {
      if (!vizElement) {
        return;
      }

      vizElement.removeEventListener(
        eventTypes.FirstInteractive,
        handleFirstInteractive,
      );

      vizElement.removeEventListener(eventTypes.TabSwitched, handleTabChange);

      vizElement.removeEventListener(
        eventTypes.VizLoadError,
        handleVizLoadError,
      );
    };
  }, [
    clientId,
    embedTableauToken,
    fundId,
    sendReportEvent,
    setQueryParams,
    siteId,
    url,
    views,
  ]);

  useEffect(() => {
    sendReportEvent({
      action: action.report.reportLoading,
      params: { report_url: url, clientId, site_id: siteId, fund_id: fundId },
    });
  }, [clientId, fundId, sendReportEvent, siteId, url]);

  return (
    <TableauVizContainer>
      {/* @ts-ignore */}
      <tableau-viz
        id="tableauViz"
        ref={vizRef}
        src={url}
        token={tableauToken}
        toolbar="top"
        width="100%">
        {(embedParams || []).map(p => (
          <viz-parameter key={p.name} name={p.name} value={p.value} />
        ))}
        {/* @ts-ignore */}
      </tableau-viz>
    </TableauVizContainer>
  );
};

export default TableauViz;
