import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'routes/constants';

const DefaultRoute = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(ROUTES.HOME);
  }, [navigate]);

  return null;
};

export default DefaultRoute;
