import { useQuery } from '@tanstack/react-query';

import { getSites } from './requests';

import { UseQueryLocalOptions } from 'config/queryClient';
import { QUERY_KEYS } from 'services/api/constants';

const useSites = (options?: UseQueryLocalOptions<Site[]>) =>
  useQuery([QUERY_KEYS.SITES], getSites, options);

export default useSites;
