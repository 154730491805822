import { useMsal } from '@azure/msal-react';
import { Button } from '@hummingbird/shared';
import { useTranslation } from 'react-i18next';

import { ContentWrapper, LoginContainer, Title } from './Login.styled';

import authBackground from 'assets/images/authBackground.png';
import { Logo } from 'components';
import { scopes } from 'config/msal';

const Login = () => {
  const { t } = useTranslation();

  const { instance } = useMsal();

  const handleMsalLogin = () => {
    instance.loginRedirect({
      scopes,
    });
  };

  return (
    <LoginContainer>
      <img alt={t('general.backgroundImage')} src={authBackground} />

      <ContentWrapper>
        <Logo />
        <Title>{t('general.welcomeToPortals')}</Title>
        <Button size="big" type="submit" onClick={handleMsalLogin}>
          {t('general.next')}
        </Button>
      </ContentWrapper>
    </LoginContainer>
  );
};

export default Login;
