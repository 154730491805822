import {
  QueryClient,
  QueryErrorResetBoundaryProps,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
// import { isAxiosError } from 'utils';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: () =>
        // if (isAxiosError(error)) {
        //   const isUnauthenticated =
        //     error?.response?.status === 401 ||
        //     error?.response?.statusText === 'Unauthorized';

        //   // Do not retry query if user is not authenticated
        //   return !isUnauthenticated;
        // }

        true,
    },
  },
});

export type UseQueryLocalOptions<Data, Payload = string, TData = Data> = Omit<
  UseQueryOptions<
    Data,
    QueryErrorResetBoundaryProps,
    TData,
    (string | Payload)[]
  >,
  'queryKey' | 'queryFn' | 'initialData'
>;

export type UseMutationLocalOptions<Data, Payload> = Omit<
  UseMutationOptions<Data, QueryErrorResetBoundaryProps, Payload>,
  'mutationFn'
>;
export default queryClient;
