import { useMutation } from '@tanstack/react-query';

import { type EmbedTokenPayload, embedToken } from './requests';

import type { UseMutationLocalOptions } from 'config/queryClient';

const useEmbedToken = (
  options?: UseMutationLocalOptions<EmbedTokenResponse, EmbedTokenPayload>,
) => useMutation(embedToken, options);

export default useEmbedToken;
