import { useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';

import TableauViz from 'components/TableauViz/TableauViz';
import { TableauVizContainer } from 'components/TableauViz/TableauViz.styled';
import TableauVizPlaceholder from 'components/TableauViz/TableauVizPlaceholder';

const DEBOUNCE_INTERVAL = 1000;

interface Props {
  isLoading: boolean;
  embedUrl: string | undefined;
  embedParams?: EmbedParams[];
}

const TableauVizRenderer = ({ isLoading, embedUrl, embedParams }: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const debouncedWidth = useDebounce(windowWidth, DEBOUNCE_INTERVAL);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isResizing = windowWidth !== debouncedWidth;

  if (!embedParams || !embedUrl) {
    return null;
  }

  return (
    <TableauVizContainer>
      {isLoading || isResizing ? (
        <TableauVizPlaceholder />
      ) : (
        <TableauViz embedParams={embedParams} url={embedUrl} />
      )}
    </TableauVizContainer>
  );
};

export default TableauVizRenderer;
