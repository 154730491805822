import type { DropdownOption } from '@hummingbird/shared';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'context/authContext';
import { action } from 'services/analytics/constants';
import useGoogleAnalytics from 'services/analytics/useGoogleAnalytics';
import useSites from 'services/api/useSites';
import { useQueryParams } from 'services/hooks';

const useSitesDropdown = () => {
  const navigate = useNavigate();
  const [{ clientId }, setQueryParams] = useQueryParams();

  const { siteId, setSiteId, embedTableauToken } = useAuth();
  const { sendInteractionEvent } = useGoogleAnalytics();

  const { data: sites = [] } = useSites();

  const sitesDropdownOptions = sites.map<DropdownOption>(site => ({
    label: site.portal_name,
    value: site.id,
  }));

  const onSiteChange = (value: string | string[]) => {
    const newSiteId = value as string;

    if (siteId === newSiteId) {
      return;
    }

    setQueryParams({
      fundId: null,
      workbookId: null,
      viewId: null,
    });

    setSiteId(newSiteId);
    embedTableauToken(newSiteId);

    sendInteractionEvent({
      action: action.interaction.siteChanged,
      params: {
        clientId,
        site_id: newSiteId,
      },
    });

    navigate('');
  };

  const isSitesDropdownDisplayed = useMemo(() => {
    const { hostname } = window.location;
    const parts = hostname.split('.');

    const portalPartIndex = parts.findIndex(part => part === 'portal');
    const subdomainIndex = portalPartIndex > 0 ? portalPartIndex - 1 : null;
    const subdomain = subdomainIndex !== null ? parts[subdomainIndex] : null;

    if (subdomain === 'staging') {
      return true;
    }

    return !subdomain && !!clientId;
  }, [clientId]);

  return {
    sitesDropdownOptions,
    isSitesDropdownDisplayed,
    onSiteChange,
  };
};

export default useSitesDropdown;
