export const category = {
  account: 'Account',
  interaction: 'Interaction',
  report: 'Report',
  tableauInteraction: 'Tableau Interaction',
};

export const action = {
  account: {
    loggedIn: 'Logged In',
    loggedOut: 'Logged out',
  },
  interaction: {
    siteChanged: 'Site changed',
    fundChanged: 'Fund changed',
    reportChanged: 'Report changed',
    viewChanged: 'View changed',
  },
  report: {
    reportLoading: 'Report started loading',
    reportLoadSuccess: 'Report loaded successfully',
    reportLoadError: 'Error loading report',
  },
  tableauInteraction: {
    // TODO Add interactions
  },
};
