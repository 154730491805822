import { Container, Content } from './Overlay.styled';

interface Props {
  text: string;
}

const Overlay = ({ text }: Props) => (
  <Container>
    <Content>
      <p>{text}</p>
    </Content>
  </Container>
);

export default Overlay;
