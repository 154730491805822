import { objectValues } from '@hummingbird/shared';
import { useQuery } from '@tanstack/react-query';

import { getProjectsHierarchy } from './requests';

import { UseQueryLocalOptions } from 'config/queryClient';
import { useAuth } from 'context/authContext';
import { QUERY_KEYS } from 'services/api/constants';

const useProjectsHierarchy = (
  options?: UseQueryLocalOptions<
    Record<number, ProjectHierarchyObject[]>,
    string,
    Record<'clients' | 'funds' | number, ProjectHierarchyObject[]>
  >,
) => {
  const { siteId } = useAuth();

  return useQuery(
    [QUERY_KEYS.PROJECT_HIERARCHY, siteId],
    () => getProjectsHierarchy(siteId),
    {
      ...options,
      select: data => {
        const { 0: clients, 1: funds, ...rest } = data;

        return {
          clients,
          funds,
          ...objectValues(rest).reduce(
            (acc, value, currentIndex) => ({
              ...acc,
              [currentIndex]: value,
            }),
            {} as Record<number, ProjectHierarchyObject[]>,
          ),
        };
      },
    },
  );
};

export default useProjectsHierarchy;
