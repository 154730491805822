import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { ROUTES } from './constants';
import DefaultRoute from './DefaultRoute/DefaultRoute';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';

const Home = lazy(() => import('pages/Home/Home'));

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <DefaultRoute />,
    children: [
      {
        path: ROUTES.HOME,
        element: (
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

export default router;
