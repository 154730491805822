import { IconButton, objectKeys } from '@hummingbird/shared';
import { useTranslation } from 'react-i18next';

import { getFirstWorkbook } from '../helpers';

import { Container, SpinnerIcon } from './ClientButton.styled';
import { clientIdToLogoMapper } from './helpers';

import { useAuth } from 'context/authContext';
import { useProjectsHierarchy } from 'services/api';
import { useQueryParams } from 'services/hooks';

const ClientButton = () => {
  const { t } = useTranslation();

  const [{ clientId, fundId }, setQueryParams] = useQueryParams();

  const { accountInfo } = useAuth();

  const { data: projectHierarchy, isLoading } = useProjectsHierarchy({
    enabled: !!accountInfo?.tenantId,
    onSuccess: data => {
      const { clients, funds, ...rest } = data;

      const currentClientId = clientId || data?.clients[0]?.id;
      const firstFund = data?.funds.find(
        item => item.parent_id === currentClientId,
      );

      const currentFundId = fundId || firstFund?.id;

      setQueryParams({
        clientId: currentClientId,
        fundId: currentFundId,
        workbookId: getFirstWorkbook(rest, currentFundId || '')?.id,
      });
    },
  });

  const doesClientLogoExists =
    objectKeys(clientIdToLogoMapper).includes(clientId);

  if (doesClientLogoExists) {
    return (
      <IconButton
        icon={clientIdToLogoMapper[clientId]}
        label={t('labels.settings')}
        variant="outlined"
      />
    );
  }

  const clientOptions = projectHierarchy?.clients?.map(item => ({
    value: item.id,
    label: item.name,
  }));

  const clientFirstLetter = clientOptions?.[0].label[0].toUpperCase();

  return (
    <Container mode="outlined" variant="secondary">
      {isLoading ? <SpinnerIcon /> : clientFirstLetter}
    </Container>
  );
};

export default ClientButton;
