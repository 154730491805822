export const getFirstWorkbook = (
  options: Record<number, ProjectHierarchyObject[]>,
  fundId: string,
): Workbook | undefined => {
  const getLevelOfNesting = (
    depth = 0,
    parentId: string,
  ): (Workbook | undefined)[] | null => {
    const currentLevel = options[depth];
    const filteredLevel = currentLevel?.filter(
      item => item.parent_id === parentId,
    );

    if (!filteredLevel) return null;

    return filteredLevel.map(({ workbooks, id }) => {
      if (workbooks && workbooks.length > 0) {
        return workbooks[0];
      }

      return getLevelOfNesting && getLevelOfNesting(depth + 1, id)?.[0];
    });
  };

  return getLevelOfNesting && getLevelOfNesting(0, fundId)?.[0];
};
