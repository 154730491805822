import { AccountInfo } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { createContext, useContext, useState } from 'react';
import { useInterval } from 'usehooks-ts';

import { useEmbedToken } from 'services/api';
import {
  clearUserSession,
  getUserSession,
  saveSiteIdToUserSession,
} from 'services/auth';

export interface AuthContextState {
  accountInfo: AccountInfo | null;
  tableauToken: string;
  siteId: string;
  setSiteId: (siteId: string) => void;
  embedTableauToken: (siteId: string) => void;
}

export interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = createContext<AuthContextState>({
  accountInfo: null,
  tableauToken: '',
  siteId: '',
  setSiteId: () => undefined,
  embedTableauToken: () => undefined,
});

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [tableauToken, setTableauToken] = useState('');
  const [siteId, setSiteId] = useState('');

  const { mutate: embedToken } = useEmbedToken();

  const embedTableauToken = (site: string) => {
    const { idToken } = getUserSession();

    if (!idToken) {
      return;
    }

    embedToken(
      { idToken, siteId: site },
      {
        onSuccess: ({ token }) => {
          setTableauToken(token);
        },
        onError: () => {
          clearUserSession();
          instance.logoutRedirect();
        },
      },
    );
  };

  const shouldTryAgainEmbeddingToken =
    isAuthenticated && !tableauToken && !!siteId;

  useInterval(
    () => {
      embedTableauToken(siteId);
    },
    shouldTryAgainEmbeddingToken ? 2000 : null,
  );

  const saveSiteId = (newSiteId: string) => {
    setSiteId(newSiteId);
    saveSiteIdToUserSession(newSiteId);
  };

  return (
    <AuthContext.Provider
      value={{
        accountInfo: instance.getActiveAccount(),
        tableauToken,
        siteId,
        setSiteId: saveSiteId,
        embedTableauToken,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
